<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('General') }}
    </p>
    <section class="filter-part content-margin">
      <div class="d-flex flex-wrap align-items-center" 
			:class="{'justify-content-between': isTiming}">
        <div class="d-flex align-items-center mb-7">
          <label>{{ $t('VAT') }}</label>
          <input type="text" class="input px-5 mx-5 vat-number" @keypress="isNumber($event)" v-model="VAT_percent_value" />
        </div>
        <div class="d-flex align-items-center timing-vat mb-7">
          <v-checkbox color="#0D3856" class="cus-checkbox" v-model="isTiming"></v-checkbox>
          <label class="px-5 color-primary">{{
            $t('Timing of VAT change')
          }}</label>
        </div>
        <div class="d-flex align-items-center mb-7" v-if="isTiming">
          <label>{{ $t('New VAT') }}</label>
          <input
            type="text"
            class="input px-5 bg-green1 color-white mx-5 new-vat"
            @keypress="isNumber($event)"
          />
        </div>
        <div class="d-flex align-items-center p-relative mb-7" v-if="isTiming">
          <Datepicker
            :format="format"
            class="input cus-datepicker"
            :language="languages[currentLang]"
          ></Datepicker>
          <img
            src="/assets/img/icons/calendar-alt-light.svg"
            alt=""
            class="calendar-img"
          />
        </div>
				<div class="d-flex align-items-center mb-7" v-if="isTiming">
					<label>{{ $t("Start hour") }}</label>
					<div class="d-flex align-items-center">
							<vue-select 
                :options="hours.options" 
                class="input cus-select time-select mx-2"
                v-model="hours.selected"
                :searchable="false"
              >
              </vue-select> 
              <span>:</span> 
              <vue-select 
                :options="mins.options" 
                class="input cus-select time-select mx-2"
                v-model="mins.selected"
                :searchable="false"
              >
              </vue-select>
					</div>
				</div>
				<button class="primary-btn mb-7" @click="updateVATValue">
					{{ $t('Save') }}
				</button>
      </div>
    </section>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="vat_list"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
      </v-data-table>
    </section>
    <section class="botton-action">
      <button class="primary-btn">
        {{ $t('Save') }}
      </button>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CustomerLists',
	components: {
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: false,
          value: 'supplier',
        },
        {
          text: this.$t('Maximum profit / commission percentage'),
          align: 'center',
          sortable: false,
          value: 'max_profit',
        },
        {
          text: this.$t('Commission level'),
          align: 'center',
          sortable: false,
          value: 'commission_level',
        },
        {
          text: this.$t('Points'),
          align: 'center',
          sortable: false,
          value: 'points',
        },
        {
          text: this.$t('Maximum amount allowed'),
          align: 'center',
          sortable: false,
          value: 'max_amount',
        },
        {
          text: this.$t('Credit payment limit'),
          align: 'center',
          sortable: false,
          value: 'payment_limit',
        },
        {
          text: this.$t('Credit'),
          align: 'center',
          sortable: false,
          value: 'credit',
        },
      ],
      vat_list: [
        {
          supplier: 'סלקום',
          max_profit: '10',
          commission_level: 'אין',
          points: '2',
          max_amount: 'אין',
          payment_limit: 'אין',
          credit: 'אין',
        },
			],
      format: "d MMMM yyyy",
      hours: {
				options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
				selected: "00"
			},
			mins: {
				options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
				selected: "00"
			},
			isTiming: false,
      VAT_percent_value: ""
    };
  },
  computed: {
    ...mapState({
      VAT_percent: (state) => state.general_settings.VAT_percent,
      loading: (state) => state.general_settings.loading
    }),
  },
  async created() {
    await this.getVATPercent();
    if (this.VAT_percent) {
      this.VAT_percent_value = this.VAT_percent;
    }
  },
  methods: {
    ...mapActions('general_settings', {
      getVATPercent: 'getVATPercent',
      updateVAT: 'updateVAT'
    }),
    async updateVATValue() {
      await this.updateVAT({vat: this.VAT_percent_value});
      await this.getVATPercent();
      this.VAT_percent_value = this.VAT_percent;
    }
  }
};
</script>
<style lang="scss">
label {
  font-size: 16px;
  margin-bottom: 0;
}
.filter-part {
	.input {
		height: 38px;
		border-radius: 5px;
	}
	.primary-btn {
		width: 100px;
		height: 36px;
		font-size: 16px;
	}
  .calendar-img {
    width: 26px;
    position: absolute;
    top: 5px;
    left: 5px;
	}
	.cus-datepicker {
		width: 180px;
	}

	.vat-number {
		width: 90px;
	}
	.new-vat {
		width: 90px;
	}
	.time-select{
		width: 90px;
		.vs__selected {
			height: 100%;
			top: 0;
		}
		.vs__dropdown-menu {
			min-width: 90px;
			max-width: 90px;
		}
		.vs__search {
			padding: 0;
		}
	}
	.timing-vat {
		margin-left: 20px;
	}
}

.botton-action {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 35px;
  .primary-btn {
    margin: 0 15px;
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
}
</style>